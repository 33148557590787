import { useEffect, useState } from "react";
import Toast from "../shared/toast";
import { apiService } from "../services/apiService";
import BannerBackground from "../assets/vector-8.png";
import BannerImage from "../assets/Login_img.png";
import { TextField, Button, Box } from "@mui/material";
import FooterBar from "../shared/footer";
import { useNavigate, useSearchParams } from "react-router-dom";
import dayjs from "dayjs";
import { authService } from "../services/authService";

const SignUp = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [password2, setPassword2] = useState("");
    const [testKitId, setTestKitId] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [password2Error, setPassword2Error] = useState("");
    const [submitted, setSubmitted] = useState(false);
    const [openToast, setOpenToast] = useState(false);
    const [msgToast, setMsgToast] = useState("");
    const [error, setError] = useState(false);
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    useEffect(() => {
        // Preenche os campos com valores da querystring
        const emailParam = searchParams.get("email");
        const testKitIdParam = searchParams.get("testkitid");
        if (emailParam) setEmail(emailParam);
        if (testKitIdParam) setTestKitId(testKitIdParam);
    }, [searchParams]);

    const validatePassword = (newPassword) => {
        if (newPassword.length < 8) {
            return "Password must be at least 8 characters long";
        } else if (!/[A-Z]/.test(newPassword)) {
            return "Password must contain at least one uppercase letter";
        } else if (!/[a-z]/.test(newPassword)) {
            return "Password must contain at least one lowercase letter";
        } else if (!/\d/.test(newPassword)) {
            return "Password must contain at least one digit";
        } else {
            return "";
        }
    };

    const submitForm = async (event) => {
        event.preventDefault();

        const passError = validatePassword(password);
        setPasswordError(passError);

        if (passError === "") {
            if (password !== password2) {
                setPassword2Error("* Passwords don´t match");
            } else {
                setPassword2Error("");
                setSubmitted(true);
                try {
                    // Cadastra o novo usuário
                    const newUser = await apiService.addClient({ email, password, role: 4 });

                    // Cadastra o novo TestResult
                    const testResult = {
                        userId: newUser.data.id,
                        testKitId,
                        expectedDropOff: dayjs().add(6, "day").toISOString(),
                    };

                    const userData = { 
                        email: newUser.data.email, 
                        password 
                    };

                    const apiLogin = await authService.login(userData, false);

                    await apiService.activatetest(testResult);

                    setMsgToast("Sign-up successful! Now you can log in.");
                    setError(false);
                    navigate("/login"); // Redireciona para a tela de login
                } catch (err) {
                    setMsgToast(
                        "An error occurred during sign-up. Please try again."
                    );
                    setError(true);
                    setSubmitted(false);
                }
                setOpenToast(true);
            }
        }
    };

    const handleCloseToast = () => {
        setOpenToast(false);
    };

    return (
        <>
            <div className="home-container">
                <div className="home-banner-container">
                    <div className="home-bannerImage-container">
                        <img src={BannerBackground} alt="" />
                    </div>
                    <div className="home-text-section">
                        <h1 className="primary-heading">Sign Up</h1>
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            <Box sx={{ maxWidth: 600, mx: "auto", p: 2 }}>
                                <form onSubmit={submitForm}>
                                    <TextField
                                        fullWidth
                                        label="Email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        margin="normal"
                                        type="email"
                                        required
                                    />
                                    <TextField
                                        fullWidth
                                        label="Password"
                                        type="password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        margin="normal"
                                        required
                                    />
                                    {passwordError && (
                                        <small className="text-danger">{passwordError}</small>
                                    )}
                                    <TextField
                                        fullWidth
                                        label="Retype Password"
                                        type="password"
                                        value={password2}
                                        onChange={(e) => setPassword2(e.target.value)}
                                        margin="normal"
                                        required
                                    />
                                    {password2Error && (
                                        <small className="text-danger">{password2Error}</small>
                                    )}
                                    <TextField
                                        fullWidth
                                        label="Confirm your TestKitId"
                                        type="text"
                                        value={testKitId}
                                        onChange={(e) => setTestKitId(e.target.value)}
                                        margin="normal"
                                        required
                                    />
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            fontSize: "12px",
                                        }}
                                    >
                                        <ul
                                            style={{
                                                listStyleType: "initial",
                                                padding: 0,
                                                textAlign: "left",
                                            }}
                                        >
                                            <li>
                                                Password must be at least 8 characters long
                                            </li>
                                            <li>
                                                Password must contain at least one uppercase letter
                                            </li>
                                            <li>
                                                Password must contain at least one lowercase letter
                                            </li>
                                            <li>
                                                Password must contain at least one digit
                                            </li>
                                        </ul>
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                        }}
                                    >
                                        <Button
                                            variant="contained"
                                            type="submit"
                                            disabled={submitted}
                                            sx={{ mt: 2 }}
                                            style={{
                                                align: "center",
                                                backgroundColor: "#1789C6",
                                                height: "60px",
                                                width: "150px",
                                                color: "white",
                                                borderBlockColor: "#1789C6",
                                                borderBlock: "0px",
                                                fontWeight: "bold",
                                                margin: "1.5rem",
                                                borderRadius: "50px",
                                            }}
                                        >
                                            Save
                                        </Button>
                                    </div>
                                </form>
                            </Box>
                        </Box>
                    </div>
                    <div className="home-image-section">
                        <img src={BannerImage} alt="" />
                    </div>
                </div>
            </div>
            <FooterBar />
            <Toast
                open={openToast}
                handleClose={handleCloseToast}
                message={msgToast}
                error={error}
            />
        </>
    );
};

export default SignUp;
