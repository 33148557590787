import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, Plugin } from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend);

// Plugin para desenhar o texto no centro do gráfico
const centerTextPlugin = {
  id: 'centerText',
  beforeDraw: (chart) => {
    const { width } = chart;
    const { height } = chart.chartArea;
    const ctx = chart.ctx;

    ctx.save();
    ctx.font = 'bold 20px Arial'; // Fonte para a porcentagem
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';

    // Obtém o valor da porcentagem
    const percentage = chart.config.data.datasets[0].data[0];

    // Define a posição para o texto da porcentagem
    const x = width / 2;
    const yPercentage = height / 1.4; // Posição ajustada para a porcentagem

    // Desenha a porcentagem
    ctx.fillStyle = '#000'; // Cor do texto
    ctx.fillText(`${percentage}%`, x, yPercentage);

    // Define a fonte e posição para o label
    ctx.font = '15px Arial'; // Fonte para o label
    const yLabel = yPercentage + 30; // Ajuste para o label logo abaixo
    const label = chart.config.options.plugins.centerText.label;

    // Desenha o label
    ctx.fillText(label, x, yLabel);

    ctx.restore();
  },
};

ChartJS.register(centerTextPlugin);

const HalfDoughnutChart = ({ percentage, result, label }) => {
  // Define a cor com base na porcentagem
  const getFillColor = (percentage) => {
    if (percentage < 25) return '#FFBABA'; // Light Red
    if (percentage < 50) return '#FFEAB6'; // Light Yellow
    if (percentage < 75) return '#9ADB91'; // Light Green
    return '#61B15A'; // Dark Green
  };

  const getResultLabel = (percentage) => {
    if (percentage < 25) return 'Low'; // Light Red
    if (percentage < 50) return 'Intermediate'; // Light Yellow
    if (percentage < 75) return 'Good'; // Light Green
    return 'Excellent'; // Dark Green
  };

  const data = {
    datasets: [
      {
        data: [percentage, 100 - percentage],
        backgroundColor: [getFillColor(percentage), '#EAEAEA'], // Cor dinâmica e fundo cinza
        hoverBackgroundColor: [getFillColor(percentage), '#EAEAEA'], // Cor ao passar o mouse
        borderWidth: 0,
      },
    ],
  };

  const options = {
    rotation: -90, // Inicia do topo
    circumference: 180, // Semicírculo
    cutout: '60%', // Espessura do anel
    plugins: {
      tooltip: { enabled: true },
      legend: { display: false }, // Remove a legenda para focar no texto central
      centerText: { label: getResultLabel(percentage) }, // Passa o label para o plugin
    },
  };

  return (
    <div style={{ textAlign: 'center' }}>
      <h5>{label}</h5>
      <Doughnut data={data} options={options} />
    </div>
  );
};

export default HalfDoughnutChart;
