import React, { useState, useEffect } from 'react';
import {
  Button, Box, DialogTitle, DialogContentText, DialogContent, Pagination, Stack, DialogActions, Dialog, TextField,
  Paper, Grid, Typography, IconButton, Tooltip, Container, Table, TableBody, TableCell, TableContainer, TableRow
} from '@mui/material';
import NavBar from "../shared/navBar";
import SideBar from "../shared/sideBar";
import { DataGrid } from '@mui/x-data-grid';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { apiService } from '../services/apiService';
import Toast from "../shared/toast";
import { authService } from '../services/authService';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import { formatDate } from '../shared/formatDate';
import LoupeOutlinedIcon from '@mui/icons-material/LoupeOutlined';
import { AlignHorizontalRight, NorthWest } from '@mui/icons-material';
import { formatNumber } from 'chart.js/helpers';
import { DateTimeField } from '@mui/x-date-pickers';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import DownloadIcon from '@mui/icons-material/Download';
import QuizIcon from '@mui/icons-material/Quiz'; // Ícone para Questionário

const ManageTestResults = () => {
  const [rows, setRows] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [filteredRows, setFilteredRows] = useState([]);
  const [openToast, setOpenToast] = useState(false);
  const [msgToast, setmsgToast] = useState('');
  const [error, setError] = useState(false);
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [openImportModal, setOpenImportModal] = useState(false);
  const [selectedTestId, setSelectedTestId] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [userId, setUserId] = useState(null);
  const [openDetailsDialog, setOpenDetailsDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [tableData, setTableData] = useState([]);
  const Tests = useState([]);
  const [PastedTests, setPastedTests] = useState(Tests);
  const [refresh, setRefresh] = useState(false);
  const [fields, setFields] = useState([]);
  const [userRole, setUserRole] = useState('');
 
  useEffect(() => {
    async function fetchData() {
      try {
        const res = await apiService.getTestResultsLabReceived();
        const testResults = res.data.map(tr => ({
          id: tr.id,
          name: tr.user.firstName + " " + tr.user.lastName,
          date: formatDate(tr.activated, false),
          testKitId: tr.testKitId,
          program: tr.user.programType.name || "",
          testNumber: tr.testNumber,
          taurine: tr.taurine || "",
          xanthine: tr.xanthine || "",
          glutamate: tr.glutamate || "",
          succinate: tr.succinate || "",
          carnitine: tr.carnitine || "",
          finished: formatDate(tr.finished, false) || '',
        }));
        setRows(testResults);
        setFilteredRows(testResults);

        const user = await authService.getUserData();
        setUserId(user.id);
      } catch (err) {
        console.log(err);
      }
    }
    fetchData();
  }, [refresh]);

  const handleResendQuestionnaire = async (testId) => {
    try {
      await apiService.createQuestionnaireUser(testId);
      setmsgToast("Questionnaire resent successfully!");
      setError(false);
    } catch (err) {
      setmsgToast("Failed to resend questionnaire.");
      setError(true);
    }
    setOpenToast(true);
  };

  useEffect(() => {
    const storeduser = authService.getUserData();
    setUserRole(storeduser.role);
}, [userRole]);

  const handlePaste = async () => {
    try {
      // Read text from clipboard in Excel format
      const textbase = await navigator.clipboard.readText();
      if (textbase === '' | textbase === null) {
        throw new Error("Nothing do paste. Try copy again.");
      }
      const text = textbase.replace(/,/g, '.').replace(/\r/g, '\t0\r');
      const rows = text.split('\n').filter(row => row.trim() !== '');

      const parsedData = rows
        .map(row => row.split('\t'))
        .filter(row =>
          // Filter based on numeric cells only (excluding first and last)
          row.slice(1, -1).every(cell => !isNaN(parseFloat(cell)) && isFinite(cell))
        )
        .map(row =>
          // Format numeric cells to 5 decimal places, excluding first and last
          row.map((cell, index) =>
            index === 0 || index === 6 ? cell : parseFloat(cell).toFixed(5)
          )
        );

      // Process the parsed data
      const processParsedData = async () => {
        const updatedPastedTests = [];

        const cont = rows[0].split('\t').filter(value => value.trim() !== '').length;
        if (cont !== 6 ) {
          throw new Error("Invalid Data. Try copy again.");
        }
        else {
          for (const row of parsedData) {
            // Validate Test Kit ID
            const response = await apiService.ValidateTestKitId(row[0]);
            const valid = response.data; // Assuming response.data is the validity status

            // Update the 'VALID' column (last column) based on the API response
            row[6] = valid ? 'OK' : 'ERROR'; // Adjust this logic based on actual API response

            // Push updated row data to array
            updatedPastedTests.push({
              testKitId: row[0],
              T: row[1],
              X: row[2],
              G: row[3],
              S: row[4],
              C: row[5],
              VALID: row[6],
            });
            // Update the state with processed data
            setPastedTests(updatedPastedTests.filter(item => item.VALID === 'OK'));
          }
        }
      };

      await processParsedData();
      setTableData(parsedData);

    } catch (err) {
      setmsgToast(err.message);
      setTableData([]);
      setOpenImportModal(false);
      setOpenToast(true);
    }
  };

  const createNumericColumn = (field, headerName, maxWidth = 50) => ({
    field,
    headerName,
    maxWidth,
    align: 'right',
    headerAlign: 'center',
    valueFormatter: (params) => {
      if (params != null & params != '') {
        return Number(params).toFixed(5);
      }
      return '';
    },
  });

  const columns = [
    { field: 'name', headerName: 'Name', minWidth: 100, AlignHorizontalRight },
    { field: 'date', headerName: 'Activated', maxWidth: 90 },
    { field: 'testKitId', headerName: 'TestKit', maxWidth: 90 },
    { field: 'program', headerName: 'Program', maxWidth: 100 },
    { field: 'testNumber', headerName: '#', maxWidth: 50 },
    createNumericColumn('taurine', 'T'),
    createNumericColumn('xanthine', 'X'),
    createNumericColumn('glutamate', 'G'),
    createNumericColumn('succinate', 'S'),
    createNumericColumn('carnitine', 'C'),
    {
      field: 'upload', 
      headerName: 'Report File', 
      align: 'center', 
      headerAlign: 'center',
      maxWidth: 110,
      renderCell: (params) => (
        <Box>
          <Tooltip title="Click to Upload Report File">
            <Button
              style={{ margin: "0px 2px 0px 0px", width: 30, height: 30, paddingTop: 10 }}
              variant="outlined"
              size="small"
              onClick={() => handleUploadClick(params.row.id)}
              disabled={params.row.taurine === '' || params.row.finished !== '-'}
            >
              <Box position="relative" display="inline-block">
                <UploadFileIcon style={{ width: 24, height: 24 }} />
              </Box>
            </Button>
          </Tooltip>
        </Box>
      )
    },
    {
      field: 'resendQuestionnaire', 
      maxWidth: 110,
      headerName: (
        <div style={{ textAlign: "center", display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <span>Resend</span>
          <span>Questionnaire</span>
        </div>
      ),
      align: 'center', 
      headerAlign: 'center',
      renderCell: (params) => (
        <Box>
          <Tooltip title="Resend Questionnaire">
            <Button
              style={{ margin: "0px 0px 0px 0px", width: 30, height: 30, paddingTop: 10 }}
              variant="outlined"
              size="small"
              onClick={() => handleResendQuestionnaire(params.row.id)}
            >
              <Box position="relative" display="inline-block">
                <QuizIcon style={{ width: 24, height: 24 }} />
              </Box>
            </Button>
          </Tooltip>
        </Box>
      )
    }
  ];

  const handleDownloadQuestionnaireAnswers = async () => {
    try {
      const response = await apiService.downloadQuestionnaireAnswers();
  
      // Cria um URL temporário para o arquivo baixado
      const url = window.URL.createObjectURL(new Blob([response.data]));
  
      // Defina o nome do arquivo que deseja usar para o download
      const fileName = `QuestionnaireAnswers_${new Date().toISOString().slice(0, 19).replace(/[-T:]/g, "")}.xlsx`;
  
      // Cria um link temporário para iniciar o download
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
  
      // Remove o link temporário após o download começar
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading questionnaire answers:", error);
    }
  };
  

  const handleSearchChange = (event) => {
    const query = event.target.value.toLowerCase();
    setSearchText(query);
    const filteredData = rows.filter(row =>
      row.name.toLowerCase().includes(query) ||
      row.program.toLowerCase().includes(query)
    );
    setFilteredRows(filteredData);
  };

  const clearSearch = () => {
    setSearchText('');
    setFilteredRows(rows);
  };

  const handleUploadClick = (testId) => {
    setSelectedTestId(testId);
    setOpenUploadModal(true);
  };

  const handleCloseUploadModal = () => {
    setOpenUploadModal(false);
    setSelectedFile(null);
    setRefresh(prev => !prev);
  };

  const handleOpenImport = () => {
    setTableData([]);
    handlePaste();
    setOpenImportModal(true);
  };

  const handleCancelImport = () => {
    setOpenImportModal(false);
    setRefresh(!refresh);
  };

  const handleImport = () => {
    processImport();
    setOpenImportModal(false);
    setRefresh(!refresh);
  };

  const processImport = async () => {
    const userdata = await authService.getUserData();
    const resultsToImport = PastedTests.map(item => ({
      TestKitId: item.testKitId,
      Taurine: item.T,
      Xanthine: item.X,
      Glutamate: item.G,
      Succinate: item.S,
      Carnitine: item.C,
      UserIdResultReported: userdata.id
    }));

    try {

      const resultsToImportJSON = JSON.stringify(resultsToImport);
      const response = await apiService.ImportResults(resultsToImportJSON);

      setmsgToast('Results updated successfully');

    } catch (error) {
      setmsgToast('Failed to import Results');
    }
    setTableData([]);
    setRefresh(prev => !prev);
    setOpenToast(true);
  };

  const handleCloseImport = () => {
    setOpenImportModal(false);
    setRefresh(prev => !prev);
  };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleFileUpload = async () => {
    if (selectedFile && selectedTestId) {
      try {
        const user = await authService.getUserData();
        const response = await apiService.uploadTestResultFile(selectedTestId, selectedFile);
        // Atualizar o TestResult com Finished e UserIdFinished
        await apiService.finishTestResult(selectedTestId);

        setmsgToast('File uploaded successfully');
        setError(false);
      } catch (error) {
        setmsgToast('Failed to upload file');
        setError(true);
      } finally {
        setOpenToast(true);
        setOpenUploadModal(false);
        setRefresh(!refresh);
      }
    }
  };

  const handleDownloadReport = async (testId) => {
    try {
      // Primeiro, busca as informações do TestResult, incluindo o FileLocation
      const testResultResponse = await apiService.getTestResultById(testId);
      const testResult = testResultResponse.data;

      if (!testResult || !testResult.fileLocation) {
        console.log("File location not found.");
        return;
      }

      // Realiza a chamada para baixar o arquivo
      const response = await apiService.downloadTestReport(testId);

      // Cria um URL temporário para o arquivo baixado
      const url = window.URL.createObjectURL(new Blob([response.data]));

      // Extrai o nome do arquivo diretamente do FileLocation no banco de dados
      const fileName = testResult.fileLocation.split('/').pop();

      // Cria um link temporário para iniciar o download
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName); // Usa o nome do arquivo obtido de FileLocation
      document.body.appendChild(link);
      link.click();

      // Remove o link temporário após o download começar
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  const handleCloseToast = () => {
    setOpenToast(false);
  };

 
  return (
    <>
      <div className="bgColor">

        <Box sx={{ display: 'flex' }}>
          <SideBar />
          <Box component="main" sx={{
            flexGrow: 1,
            height: '100vh',
            overflow: 'visible',
            p: 3,
          }}>
            <NavBar />
            <Container maxWidth="lg" sx={{ mt: 2, mb: 2 }}>
              <Paper sx={{ p: 2 }}>
                <Typography variant="h4" sx={{ mb: 2 }}>Test Results</Typography>
                {/* Campo de busca */}
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      placeholder="Search Test Results"
                      value={searchText}
                      onChange={handleSearchChange}
                      InputProps={{
                        startAdornment: <SearchIcon />,
                        endAdornment: (
                          <IconButton onClick={clearSearch}>
                            <ClearIcon />
                          </IconButton>
                        )
                      }}
                    />
                  </Grid>

                  <Grid item xs={6} sx={{ marginTop: "10px" }}>
                  {userRole === "Lab_Manager" && (
                    <Tooltip title="Click to paste data from Excel">
                      <IconButton
                        onClick={handleOpenImport}
                        sx={{
                          backgroundColor: "rgba(197, 220, 255, 1)",
                          color: "#000000",
                          fontWeight: "bold",
                          borderColor: "rgba(197, 220, 255, 1)",
                          "&:hover": {
                            backgroundColor: "rgba(150, 200, 255, 1)"
                          }
                        }}
                      >
                        <ContentPasteIcon />
                      </IconButton>
                    </Tooltip>
                  )}

                  {userRole === "Admin" && (
                    <Tooltip title="Download Questionnaire Answers">
                        <IconButton
                          onClick={handleDownloadQuestionnaireAnswers}
                          sx={{
                            backgroundColor: "rgba(197, 220, 255, 1)",
                            color: "#000000",
                            fontWeight: "bold",
                            borderColor: "rgba(197, 220, 255, 1)",
                            "&:hover": {
                            backgroundColor: "rgba(150, 200, 255, 1)"
                          }
                        }}
                      >
                        <DownloadIcon />
                      </IconButton>
                    </Tooltip>          
                  )}
                  </Grid>
                </Grid>
                {/* Grid de resultados */}
                <Grid item xs={12} sx={{ mt: 2 }}>

                  <DataGrid
                    fullWidth
                    rows={filteredRows}
                    columns={columns.map((column) => ({
                      ...column,
                      flex: 1, // Allows each column to auto-size based on content
                      p: 2

                    }))}
                    initialState={{
                      pagination: {
                        paginationModel: {
                          pageSize: 5,
                        },
                      },
                    }}
                    pageSizeOptions={[5]}
                    disableSelectionOnClick // Disables row selection on click
                    checkboxSelection={false} // Removes the checkbox column
                    components={{
                      Pagination: () => (
                        <Pagination
                          count={Math.ceil(filteredRows.length / 5)}
                          shape="rounded"
                          hidePrevButton={filteredRows.length <= 5} // Hide previous button if only one page
                          hideNextButton={filteredRows.length <= 5} // Hide next button if only one page
                        />
                      ),
                    }}
                    sx={{
                      "& .MuiDataGrid-columnHeaderTitle": {
                        whiteSpace: "normal",
                        lineHeight: "normal"
                      },
                      '& .MuiTablePagination-selectLabel': {
                        display: 'none',      // Hide the "Rows per page" text
                      },
                      '& .MuiTablePagination-displayedRows': {
                        display: 'none',      // Hide the "6–10 of 22" text
                      },
                      '& .MuiInputBase-root': {
                        display: 'none',      // Hide the rows per page dropdown
                      },
                      backgroundColor: "#ffffff",
                      "& .MuiDataGrid-columnHeaders": {
                        fontWeight: "bold",  // Set the font weight of headers to bold
                      },
                      "& .MuiDataGrid-columnHeaderTitle": {
                        fontWeight: "bold",  // Ensure text in headers is bold
                      },
                    }}
                  />
                </Grid>
                {/* Modal de upload */}
                <Dialog open={openUploadModal} onClose={handleCloseUploadModal}>
                  <DialogTitle>Upload Test Result File</DialogTitle>
                  <DialogContent>
                    <DialogContentText>Upload a file for the selected test result.</DialogContentText>
                    <input type="file" onChange={handleFileChange} />
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleCloseUploadModal} color="primary">Cancel</Button>
                    <Button onClick={handleFileUpload} color="primary" disabled={!selectedFile}>Upload</Button>
                  </DialogActions>
                </Dialog>
              </Paper>
            </Container>
          </Box>
        </Box>
      </div>
      <Dialog open={openImportModal} handleClose={handleCloseImport} maxWidth="sm" fullWidth>
        <DialogTitle>Import data from Excel</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Importing data from the results spreadsheet
          </DialogContentText>
          <div>
            {tableData.length > 0 && (
              <table border="1">
                <thead>
                  <tr>
                    {['KIT', 'T', 'X', 'G', 'S', 'C', 'VALID'].map((col, index) => (
                      <th key={index} style={{ width: '100px', textAlign: 'center' }}>{col}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {tableData.map((row, rowIndex) => (
                    <tr
                      key={rowIndex}
                      style={{
                        backgroundColor: row[6] === 'ERROR' ? 'red' : 'transparent',
                      }}
                    >
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} style={{ width: '100px', textAlign: 'right' }}>{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>

        </DialogContent>
        <DialogActions>
          <Button onClick={handleImport} color="primary">Save</Button>
          <Button onClick={handleCloseImport} color="primary">Close</Button>
        </DialogActions>
      </Dialog>
      <Toast open={openToast} handleClose={handleCloseToast} message={msgToast} error={error} />
    </>
  );
};
export default ManageTestResults;
