import axiosInstance from "../axios/axiosInstance";
import { authService } from "./authService";


//USERS

const userinfoupdate = async (userData) => {
  const user = await authService.getUserData();
  return await axiosInstance.post("User/Update", userData, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${user.token}`
    }
  });
}

const userAcceptTerms = async () => {
  const user = await authService.getUserData();
  const uData = `{ "Param": "${String(user.id)}" }`
  return await axiosInstance.post("User/AcceptTerms", uData, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${user.token}`
    }
  });
}

const addClient = async (userData) => {
  return await axiosInstance.post("User/AddClient", userData);
}

const useradd = async (userData) => {
  const user = await authService.getUserData();
  return await axiosInstance.post("User/Add", userData, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${user.token}`
    }
  });
}

const newpassword = async (userData) => {
  return await axiosInstance.post("User/newpassword", userData);
};

const mailresetpassword = async (userData) => {
  return await axiosInstance.post(`User/Reset/${userData}`);
}

const validatemailtoken = async (userData) => {
  return await axiosInstance.post("Token/ValidateToken", userData);
}

const validatetoken = async (userData) => {
  return await axiosInstance.post("User/ValidateToken", userData, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${userData.token}`
    }
  });
}

const userbyid = async (userData) => {
  //const user = await authService.getUserData();
  return await axiosInstance.post("User/ById", userData, {
    headers: {
      'Content-Type': 'application/json'
    }
  });
}

const verifyEmail = async (userData) => {
  const userid = await axiosInstance.post("User/VerifyEmail", userData, {
    headers: {
      'Content-Type': 'application/json'
    }
  });
  return userid
}

const usersAll = async () => {
  const userData = await authService.getUserData();
  if (userData.role === 'Admin') //se for o Adm, traz tudo
  {
    return await axiosInstance.post("User/AllUsers", {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${userData.token}`
      }
    });
  }
  else {
    const userData = await authService.getUserData();
    const uData = `{ "Param": "${String(userData.selectedPartner)}" }`
    return await axiosInstance.post("User/PartnerByPartnerId", uData, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${userData.token}`
      }
    });
  }
}

const userbypartnerid = async () => {
  const userData = await authService.getUserData();
  const uData = `{ "Param": "${String(userData.selectedPartner)}" }`
  return await axiosInstance.post("User/ByPartnerId", uData, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${userData.token}`
    }
  });
}

const clientsForAdm = async () => {
  const userData = await authService.getUserData();
  return await axiosInstance.post("User/ClientsForAdm", {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${userData.token}`
    }
  });
}

const allUsersPartners = async () => {
  const user = await authService.getUserData();
  return await axiosInstance.post("UserPartner/AllUsersPartners", {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${user.token}`
    }
  });
}

const admbypartnerid = async (userData) => {
  const user = await authService.getUserData();
  return await axiosInstance.post("User/AdmByPartnerId", userData, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${user.token}`
    }
  });
}

const addUserToPartner = async (userPartnerData) => {
  const user = await authService.getUserData();
  return await axiosInstance.post("UserPartner/Add", userPartnerData, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${user.token}`
    }
  });
};

const clientsByPartner = async (searchModel) => {
  const user = await authService.getUserData();
  return await axiosInstance.post("User/ClientsByPartner", searchModel, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${user.token}`
    }
  });
};

//PARTNER

const partnerall = async () => {
  return await axiosInstance.post("Partner/All");
};

const partnerbyname = async (userData) => {
  const uData = `{ "Param": "${String(userData)}" }`
  return await axiosInstance.post("Partner/ByName", uData, {
    headers: {
      'Content-Type': 'application/json'
    }
  });
};

const partneradd = async (userData) => {
  console.log(userData);
  const user = await authService.getUserData();
  return await axiosInstance.post("Partner/Add", userData, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${user.token}`
    }
  });
}

const partnertypeall = async () => {
  return await axiosInstance.post("partnertype/All");
}

const partnertypebyid = async (userData) => {
  return await axiosInstance.post("partnertype/ById", userData);
}

const partnerbyid = async (partnerid) => {
  const pData = `{ "Param": "${String(partnerid)}" }`;
  return await axiosInstance.post("partner/ById", pData, {
    headers: {
      'Content-Type': 'application/json'
    }
  });
}

const partnerByUserId = async () => {
  const userData = await authService.getUserData();
  const pData = `{ "Param": "${String(userData.id)}" }`

  return await axiosInstance.post("Partner/ByUserId", pData, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${userData.token}`
    }
  });
}

const bepartnermail = async (userData) => {
  return await axiosInstance.post("Partner/bePartnerMail", userData, {
    headers: {
      'Content-Type': 'application/json'
    }
  });
}

//ADDRESS

const addressbyid = async (userData) => {
  return await axiosInstance.post("Address/ById", userData, {
    headers: {
      'Content-Type': 'application/json'
    }
  });
}

//PROVINCE

const provinceorstateall = async () => {
  return await axiosInstance.post("ProvinceOrState/All");
}

//CITY

const citiesall = async () => {
  return await axiosInstance.post("City/All");
}

//KITS - RESULTS

const addPartnerTestKit = async (data) => {
  const user = await authService.getUserData();
  return await axiosInstance.post("PartnerTestKit/Add", data, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${user.token}`
    }
  });
};

const deletePartnerTestKit = async (id) => {
  const user = await authService.getUserData();
  return await axiosInstance.post(`PartnerTestKit/RemoveKit/${id}`, {
    headers: {
      'Authorization': `Bearer ${user.token}`
    }
  });
};

const getTestResultById = async (testResultId) => {
  const user = await authService.getUserData();
  return await axiosInstance.get(`TestResult/ById/${testResultId}`, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${user.token}`
    }
  });
};

const finishTestResult = async (testResultId) => {
  const user = await authService.getUserData();
  const userIdFinished = +user.id;

  return await axiosInstance.post(`TestResult/FinishTest/${testResultId}/${userIdFinished}`, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${user.token}`
    }
  });
};

const getTestResultsByUserId = async () => {
  const userData = await authService.getUserData();

  return await axiosInstance.get(`TestResult/ByUserId/${userData.id}`, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${userData.token}`
    }
  });
}

const giftTestKit = async (email, testKitId) => {
  const user = await authService.getUserData();

  return await axiosInstance.get(`TestResult/GiftTestKit?email=${encodeURIComponent(email)}&testKitId=${encodeURIComponent(testKitId)}`, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${user.token}`
    }
  });
};


const ValidateTestKitId = async (testKitId) => {
  const userData = await authService.getUserData();
  const pData = `{ "Param": "${String(testKitId)}" }`

  return await axiosInstance.post("TestResult/ValidateTestKitId", pData, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${userData.token}`
    }
  });
}

const getPartnerTestKits = async (partnerId) => {
  const user = await authService.getUserData();
  return await axiosInstance.get(`PartnerTestKit/GetByPartner/${partnerId}`, {
    headers: {
      'Authorization': `Bearer ${user.token}`
    }
  });
};

const getAllProgramTypes = async () => {
  const user = await authService.getUserData();
  return await axiosInstance.get("ProgramType/All", {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${user.token}`
    }
  });
};

const ImportResults = async (testKits) => {
  const userData = await authService.getUserData();
  const pData = testKits;

  return await axiosInstance.post("TestResult/ImportResults", pData, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${userData.token}`
    }
  });
}

const importTestScores = async (testKits) => {
  const userData = await authService.getUserData();
  const pData = testKits;

  return await axiosInstance.post("TestResult/ImportTestScores", pData, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${userData.token}`
    }
  });
}

const activatetest = async (userData) => {
  console.log(userData);
  const user = await authService.getUserData();
  userData.userIdActivated = Number(user.id);
  return await axiosInstance.post("TestResult/Activate", userData, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${user.token}`
    }
  });
}

const getByTestKitId = async (userData) => {
  const user = await authService.getUserData();
  const uData = `{ "Param": "${String(userData)}" }`
  return await axiosInstance.post("TestResult/ByTestKitId", uData, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${user.token}`
    }
  });
};

const updateTestResult = async (userData) => {
  console.log(userData);
  const user = await authService.getUserData();
  return await axiosInstance.post("TestResult/SimpleUpdate", userData, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${user.token}`
    }
  });
}

const receivetest = async (userData) => {
  console.log(userData);
  const user = await authService.getUserData();

  userData.userIdLabReceived = user.id;
  userData.labReceived = new Date();
  return await axiosInstance.post("TestResult/LabReceive", userData, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${user.token}`
    }
  });
}

const getAllTestResults = async () => {
  const user = await authService.getUserData();

  const uData = `{ "Param": "${String(user.selectedPartner)}" }`

  return await axiosInstance.get("TestResult/All",
    uData, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${user.token}`
    }
  });
};

const getTestResultsLabReceived = async () => {
  const user = await authService.getUserData();

  return await axiosInstance.get("TestResult/TestsLabReceived", {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${user.token}`
    }
  });
};

const getTestScores = async () => {
  const user = await authService.getUserData();

  return await axiosInstance.get("TestResult/TestScores", {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${user.token}`
    }
  });
};


//todos os users do partner com resultados (se tiver)
const getResultxUsers = async () => {
  const userData = await authService.getUserData();
  //se não houver partner, pode ser um ADM. Isso será validado no BackEnd
  const uData = `{ "Param": "${String(userData.selectedPartner === null ? '0' : userData.selectedPartner)}" }`

  return await axiosInstance.post("TestResult/ResultxUsers",
    uData, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${userData.token}`
    }
  });
};

const getActiveNotReceived = async () => {
  const userData = await authService.getUserData();
  
  // Se não houver partner, pode ser um ADM, validado no BackEnd
  const partnerId = userData.selectedPartner === null ? '0' : String(userData.selectedPartner);

  // Montando a URL com o parâmetro PartnerId
  const endpoint = `TestResult/ActiveNotReceived?partnerId=${partnerId}`;

  return await axiosInstance.get(endpoint, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${userData.token}`
    }
  });
};

const getActiveNotFinished = async () => {
  const userData = await authService.getUserData();

  // Montando a URL com o parâmetro PartnerId
  const endpoint = `TestResult/ActiveNotFinished`;

  return await axiosInstance.get(endpoint, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${userData.token}`
    }
  });
};


const getTestProgress = async () => {
  const userData = await authService.getUserData();
  //se não houver partner, pode ser um ADM. Isso será validado no BackEnd
  const uData = `{ "Param": "${String(userData.selectedPartner === null ? '0' : userData.selectedPartner)}" }`

  return await axiosInstance.post("TestResult/TestProgress",
    uData, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${userData.token}`
    }
  });
};

const forwardResultFile = async (testId) => {
  const user = await authService.getUserData();

  return await axiosInstance.post(`/TestResult/ForwardResultFile/${testId}`, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${user.token}`
    }
  });
};

//QUESTIONAIRE

const getQuestionnaireById = async (id) => {
  const user = await authService.getUserData();
  return await axiosInstance.get(`Questionnaire/ById/${id}`, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${user.token}`
    }
  });
};

const getUserQuestionnaires = async (userId) => {
  return await axiosInstance.get(`QuestionnaireUser/ByUserId/${userId}`, {
    headers: {
      'Content-Type': 'application/json'
    }
  });
};

const getUserQuestionnaireById = async (id) => {
  return await axiosInstance.get(`QuestionnaireUser/ById/${id}`, {
    headers: {
      'Content-Type': 'application/json'
    }
  });
};

const addUserQuestionnaireAnswer = async (answer) => {
  return await axiosInstance.post('QuestionnaireUserAnswers/Add', answer, {
    headers: {
      'Content-Type': 'application/json'
    }
  });
};

const updateQuestionnaireUser = async ({ questionnaireUser }) => {
  const user = await authService.getUserData();
  questionnaireUser.questionnaire = null; // Nullify navigation properties if needed

  // Log the payload before sending the request
  console.log('Payload:', JSON.stringify(questionnaireUser));

  const payload = { ...questionnaireUser };
  return await axiosInstance.post('QuestionnaireUser/Update', payload, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${user.token}`
    }
  });
};



const getQuestionnaireUserAnswersByQuestionnaireUserId = async (questionnaireUserId) => {
  const user = await authService.getUserData();
  return await axiosInstance.get(`QuestionnaireUserAnswers/ByQuestionnaireUserId/${questionnaireUserId}`, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${user.token}`
    }
  });
};

const addQuestionnaireUserAnswer = async (answer) => {
  const user = await authService.getUserData();
  return await axiosInstance.post('QuestionnaireUserAnswers/Add', answer, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${user.token}`
    }
  });
};

const createQuestionnaireUser = async (testResultId) => {
  const user = await authService.getUserData();
  return await axiosInstance.post('QuestionnaireUser/Create', testResultId, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${user.token}`
    }
  });
};

//FILES
// Função para fazer o upload do arquivo para o servidor
const uploadTestResultFile = async (testResultId, file) => {
  const user = await authService.getUserData();
  const formData = new FormData();
  formData.append('file', file);

  return await axiosInstance.post(`TestResult/UploadFile/${testResultId}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      'Authorization': `Bearer ${user.token}`
    }
  });
};

const downloadTestReport = async (testResultId) => {
  const user = await authService.getUserData();

  // Faz a chamada para o endpoint de download
  return await axiosInstance.get(`TestResult/DownloadFile/${testResultId}`, {
    headers: {
      'Authorization': `Bearer ${user.token}`
    },
    responseType: 'blob' // Isso é importante para que o arquivo seja baixado corretamente
  });
};

const completeQuestionnaireUser = async (idQuestionnaireUser) => {
  try {
    const user = await authService.getUserData();
    return await axiosInstance.post(`/QuestionnaireUser/Complete/${idQuestionnaireUser}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${user.token}`
      }
    });
  } catch (error) {
    console.error('Error completing questionnaire user:', error);
    throw error;
  }
};

const getTestResultQuestionnaireUserActive = async (userId) => {
  const user = await authService.getUserData();
  return await axiosInstance.get(`TestResult/TestResultQuestionnaireUserActive/${userId}`, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${user.token}`
    }
  });
};


const downloadQuestionnaireAnswers = async () => {
  const user = await authService.getUserData();

  // Faz a chamada para o endpoint de download
  return await axiosInstance.get("TestResult/DownloadQuestionnaireAnswers", {
    headers: {
      'Authorization': `Bearer ${user.token}`
    },
    responseType: 'blob' // Isso é importante para que o arquivo seja baixado corretamente
  });
};


export const apiService = {
  newpassword, mailresetpassword, validatemailtoken, validatetoken, userAcceptTerms, userinfoupdate, userbyid, verifyEmail, useradd, addressbyid, addUserToPartner,
  provinceorstateall, partneradd, partnerall, partnertypeall, partnertypebyid, partnerbyid, bepartnermail, admbypartnerid,
  citiesall, clientsForAdm, userbypartnerid, getAllTestResults, getResultxUsers, clientsByPartner, partnerByUserId, usersAll, partnerbyname,
  getQuestionnaireById,
  getUserQuestionnaires,
  addUserQuestionnaireAnswer,
  updateQuestionnaireUser,
  getQuestionnaireUserAnswersByQuestionnaireUserId, activatetest, receivetest, getByTestKitId,
  addQuestionnaireUserAnswer,
  addPartnerTestKit,
  deletePartnerTestKit,
  getPartnerTestKits,
  getTestProgress,
  getAllProgramTypes,
  uploadTestResultFile,
  getTestResultsLabReceived,
  downloadTestReport,
  getTestResultById,
  updateTestResult,
  finishTestResult,
  ValidateTestKitId,
  forwardResultFile,
  ImportResults,
  allUsersPartners,
  completeQuestionnaireUser,
  getUserQuestionnaireById,
  createQuestionnaireUser,
  getTestResultQuestionnaireUserActive,
  downloadQuestionnaireAnswers,
  getTestScores,
  importTestScores,
  getActiveNotReceived,
  getActiveNotFinished,
  addClient,
  getTestResultsByUserId,
  giftTestKit
};
